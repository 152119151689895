/**
 * This file is mainly consumed by ConfirmationWizardModule to pass apiUrl to DynamicFormGeneratorModule
 */

import { InjectionToken } from '@angular/core';
import { DefaultPrivacyLevel } from '@datadog/browser-rum';

export let confirmationApplicationConfig: AppConfig = {
	environmentName: '',
	// backend API url
	apiUrl: '',
	datadogRum: {
		applicationId: '',
		clientToken: '',
		site: '',
		service: '',
		env: '',
		version: '',
		sampleRate: 100,
		trackInteractions: false,
		defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
	},
	splitServiceConfig: {
		core: {
			authorizationKey: '',
			key: '',
		},
		features: {},
		scheduler: {
			offlineRefreshRate: 0,
		},
	},
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setAppConfig(data: any): void {
	confirmationApplicationConfig = data;
}

export class AppConfig {
	public environmentName!: string;
	public apiUrl!: string;
	public production?: boolean;
	public datadogRum!: {
		applicationId: string;
		clientToken: string;
		site: string;
		service: string;
		env: string;
		version: string;
		sampleRate: number;
		trackInteractions: boolean;
		defaultPrivacyLevel: DefaultPrivacyLevel;
	};
	public splitServiceConfig!: {
		core: {
			authorizationKey: string;
			key: string;
		};
		features: Record<string, never>;
		scheduler: {
			offlineRefreshRate: number;
		};
	};
}

export const APP_CONFIG: InjectionToken<AppConfig> =
	new InjectionToken<AppConfig>('APP_CONFIG');
