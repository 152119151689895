// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppConfig } from 'src/app/app.config';

export const environment = {
	production: false,
	environmentName: 'CI',
	apiUrl: 'https://ci.api.confirmation.8067.aws-int.thomsonreuters.com',
	auth: {
		domain: 'auth-nonprod.thomsonreuters.com',
		clientId: 'vtBCC4A6AMArjW6AsbWOOGxS0rbzA1sI',
		redirectUri: 'http://localhost:4200/authentication',

	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
