import {
	APP_INITIALIZER,
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
} from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BentoModule } from '@bento/bento-ng';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BentoD3Module } from '@bento/bento-ng-d3';
import { BentoD3DataMapModule } from '@bento/bento-ng-datamap';
import { BentoTransferboxModule } from '@bento/bento-ng-transferbox';
import { BentoFlexGridControlModule } from '@bento/bento-ng-flexgrid-control';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { WjGridGrouppanelModule } from '@grapecity/wijmo.angular2.grid.grouppanel';
import { BfmModule } from '@bento/bfm-ng';
import { EmailFieldModule } from '@aafm-confirmation/email-field';
import { AuthorizationRequestedModule } from '@aafm-confirmation/authorization-requested';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { I18nService } from './services/i18n.service';
import { CommonModule } from '@angular/common';
import {
	APP_CONFIG,
	AppConfig,
	confirmationApplicationConfig,
} from './app.config';
import { datadogRum } from '@datadog/browser-rum';
import {
	FeatureFlagService,
	SaffronStylesLoaderService,
} from '@aafm-confirmation/shared-services';
import { saffronComponents } from 'src/utilities/saffron-imports';
saffronComponents();
@NgModule({
	declarations: [AppComponent],
	imports: [
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		BentoModule,
		BfmModule,
		NgbModule,
		BentoD3Module,
		BentoD3DataMapModule,
		BentoTransferboxModule,
		BentoFlexGridControlModule,
		WjGridModule,
		WjInputModule,
		WjGridFilterModule,
		WjGridGrouppanelModule,
		EmailFieldModule,
		CommonModule,
		AuthorizationRequestedModule.forRoot({
			apiUrl: confirmationApplicationConfig.apiUrl,
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, I18nService, APP_CONFIG],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: featureFlagInitializerFactory,
			deps: [FeatureFlagService, APP_CONFIG],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (saf: SaffronStylesLoaderService) => () =>
				saf.setProductFeatureFlag('Confirmation_Authorization_Saffron'),
			deps: [SaffronStylesLoaderService, FeatureFlagService],
			multi: true,
		},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n/', suffix: '.json' },
		{ prefix: '/assets/i18n/authorization/', suffix: '.json' },
	]);
}

export function appInitializerFactory(
	translate: TranslateService,
	i18nService: I18nService,
	config: AppConfig
): object {
	// tslint:disable-next-line:typedef
	const DEFAULT_LANG = 'en-US';
	return async (): Promise<object> =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		new Promise<object>((resolve: any): void => {
			if (!i18nService.getCurrentLang()) {
				translate.setDefaultLang(DEFAULT_LANG);
				resolve();
			}

			// Initialize Datadog RUM Browser Monitoring
			datadogRum.init({
				applicationId: config.datadogRum.applicationId,
				clientToken: config.datadogRum.clientToken,
				site: config.datadogRum.site,
				service: config.datadogRum.service,
				env: config.datadogRum.env,
				version: config.datadogRum.version,
				sampleRate: config.datadogRum.sampleRate,
				defaultPrivacyLevel: config.datadogRum.defaultPrivacyLevel,
			});

			datadogRum.startSessionReplayRecording();
		});
}

export function featureFlagInitializerFactory(
	featureFlagService: FeatureFlagService,
	config: AppConfig
): unknown {
	return () => {
		const userId = 'Authorization_FE';
		const splitServiceConfig = config.splitServiceConfig;
		featureFlagService.initPlugin(splitServiceConfig, userId);
	};
}
