<div class="global-header">
	<!-- Logo -->
	<div class="topbar-left">
		<!-- Logo name or Image -->
		<div class="topbar-item topbar-logo">
			<h1 class="topbar-parent-app">
				{{ "Authorization.CompanyName" | translate }}
			</h1>
			<h2 class="topbar-child-app">{{ "Authorization.ProductName" | translate }}</h2>
		</div>
	</div>
</div>
<confirmation-authorization-requested [authorizationId]="authorizationId" [emailRequestId]="emailRequestId"
	[token]="token" [domainUrl]="domainUrl">
</confirmation-authorization-requested>