import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setConsumerData } from '@bento/bento-ng';
import { APP_CONFIG, setAppConfig } from './app/app.config';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
/* tslint:disable */
fetch('/assets/configurations/app.config.json')
	.then((response): Promise<any> => response.json())
	.then((config): any =>
	{
		setAppConfig(config);
		if (environment.production)
		{
			enableProdMode();
		}

		// ng-bento consumer data setup
		setConsumerData({
			assetId: 601044,
		});

		platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
			.bootstrapModule(AppModule)
			.catch((err): void => console.error(err));
	})
	/* tslint:disable */
	.catch((reason: any): void =>
	{
		console.error('FAILED TO FETCH RUNTIME CONFIGURATION:', reason);
	});
