import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class I18nService {
	private readonly DEFAULT_LANG: string = 'en-US';
	private readonly FR_LANG_CODE: string = 'fr-CA';

	constructor(private translateService: TranslateService) {}

	changeLanguage(locale: string): void {
		locale = locale === this.FR_LANG_CODE ? locale : this.DEFAULT_LANG;
		this.translateService.use(locale);
	}

	setDefaultLanguage(locale: string): void {
		this.translateService.use(locale);
	}

	getCurrentLang(): string {
		return this.translateService.currentLang;
	}
}
