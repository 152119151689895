import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { I18nService } from './services/i18n.service';
import { ActivatedRoute } from '@angular/router';
import { confirmationApplicationConfig } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	authorizationId: string;
	emailRequestId: string;
	token: string;
	domainUrl: string;
	cultureCode: string;
	constructor(
		private i18nService: I18nService,
		private router: ActivatedRoute,
		private titleService: Title,
		private translateService: TranslateService
	) {}
	@ViewChildren(NgbTooltip) tooltips: QueryList<NgbTooltip>;

	ngOnInit(): void {
		this.setTitle();
		this.domainUrl = confirmationApplicationConfig.apiUrl;
		this.router.queryParams.subscribe((): void => {
			if (this.router.snapshot.queryParamMap.get('aId') !== null) {
				this.authorizationId = this.router.snapshot.queryParamMap
					.get('aId')
					.toString();
			}
			if (this.router.snapshot.queryParamMap.get('token') !== null) {
				this.token = this.router.snapshot.queryParamMap.get('token').toString();
			}
			if (this.router.snapshot.queryParamMap.get('lng') !== null) {
				this.i18nService.changeLanguage(
					this.router.snapshot.queryParamMap.get('lng')
				);
				this.cultureCode = this.router.snapshot.queryParamMap.get('lng');
			}
			if (this.router.snapshot.queryParamMap.get('rId') !== null) {
				this.emailRequestId = this.router.snapshot.queryParamMap
					.get('rId')
					.toString();
			}
		});
	}
	private setTitle() {
		this.translateService.get('Authorization.PageTitle').subscribe(() => {
			const pageTitle: string = this.translateService.instant(
				'Authorization.PageTitle'
			) as string;
			this.titleService.setTitle(pageTitle);
		});
	}
}
